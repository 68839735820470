import React, { useContext, useState } from "react";
import LobbyHeader from "./LobbyHeader/LobbyHeader";
import GameSearchAndFilter from "./GameSearchAndFilter/GameSearchAndFilter";
import GamesGrid from "./GamesGrid/GamesGrid";
import PlayerLobby from "./PlayerLobby/PlayerLobby";
import { COLORS } from "./../../colors";
import { useMediaQuery } from "./../../Utils/mediaQuery";
import { SocketContext } from './../../context/socket';

const Lobby = (props) => {
  const socket = useContext(SocketContext).socket;
  const GSWebRTCWrapper = useContext(SocketContext).GSWebRTCWrapper;
  const [hasJoinedRoom, setHasJoinedRoom] = useState(false);
  const [hasClickedJoin, setHasClickedJoin] = useState(false);
  const [playerList, setPlayerList] = useState([]);
  const [lobbyCode, setLobbyCode] = useState("");

  GSWebRTCWrapper.setFunc(props.setVideoStreams);

  let gameCardGridColumn = "grid-cols-2";
  if (useMediaQuery("xl")) {
    gameCardGridColumn = "grid-cols-2";
  } else {
    gameCardGridColumn = "grid-cols-1";
  }

  const setupSocketRoom = () => {
    socket.emit('createNewLobby', { userDetails: props.userDetails});
  }

  const getMediaAccess = async () => {
    const constraints = {
      'video': true,
      'audio': true
    }
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      stream.customMuted = true;
      stream.customName = "local";
      console.log("Got MediaStream:",stream);
      props.setVideoStreams((prevStreams) => [...prevStreams, stream]);
      GSWebRTCWrapper.setLocalStreamTracks(stream);
    } catch (error) {
      console.error('Error accessing media devices.', error);
    }
  };

  const handleCreateGame = () => {
    console.log("heelo from handle create game");
    setupSocketRoom();
    props.setuserDetails({
      ...props.userDetails,
      isAdmin: true
    });

    if (featureFlags.shouldSetupWebRTC) {
      getMediaAccess();
    }
  }

  const sendStartGame = () => {
    socket.emit('startGame', {"lobbyCode":lobbyCode});
  }

  const triggerJoinLobbyPopUp = () => {
    setHasClickedJoin(true);
    console.log("hello from triggerJoinLobbyPopUp");
    // socket.emit('joinLobby');
  }

  const lobbyCreatedCallback = (message) => {
    console.log("lobbyCreatedCallback", message);
    // Update LobbyHeader by removing create and join room buttons and add Exit lobby button
    // Start showing PlayerLobby component
    setHasJoinedRoom(true);
    setLobbyCode(message.lobbyCode);

    // webrtc
    if (featureFlags.shouldSetupWebRTC) {
      GSWebRTCWrapper.setLobbyCode(message.lobbyCode);
    }

    // Set Current Player Details on create Room
    setPlayerList([{
      userName: props.userDetails.userName,
      userAvatarPath: props.userDetails.userAvatarPath
    }]);
  }

  const lobbyJoinedCallback = (message) => {
    console.log("lobbyJoinedCallback", message);
    // Update Player List
    setPlayerList(message.lobbyDetails.playerList);
    // Update UI so that Player has joined lobby
    setLobbyCode(message.lobbyDetails.lobbyCode)
    setHasJoinedRoom(true);
  }

  const handleNewUserConnected = (message) => {
    setPlayerList([...playerList, {
      userName: message.userDetails.userName,
      userAvatarPath: message.userDetails.userAvatarPath
    }]);
  }

  const handleJoinLobby = async (lobbyCode) => {
    console.log("handleJoinLobby", lobbyCode);
    socket.emit('joinLobby', {"lobbyCode":lobbyCode, "userDetails":props.userDetails});

    if (featureFlags.shouldSetupWebRTC) {
      await getMediaAccess();
      await GSWebRTCWrapper.setLobbyCode(lobbyCode);
      await GSWebRTCWrapper.createOffer();
    }
  }

  // Socket Event Listeners
  socket.on('lobbyCreated', lobbyCreatedCallback);
  socket.on('connectedToLobby', lobbyJoinedCallback)
  socket.on('newUserConnected', handleNewUserConnected);
  socket.on('GameStarted', () => {props.setPage('tictactoe');});

  return (
    <div
      className="min-h-screen py-10"
      style={{ backgroundColor: COLORS.commonBackGroundPrimary }}
    >
      <div className="ml-10 mr-10">
        <LobbyHeader handleCreateGame={handleCreateGame} triggerJoinLobbyPopUp={triggerJoinLobbyPopUp} hasJoinedRoom={hasJoinedRoom} userDetails={props.userDetails} />
        { ( hasJoinedRoom || hasClickedJoin) ? <PlayerLobby hasJoinedRoom={hasJoinedRoom} lobbyCode={lobbyCode} handleJoinLobby={handleJoinLobby} playerList={playerList} /> : null}
        <GameSearchAndFilter />
        <GamesGrid
          className={[gameCardGridColumn, "mt-6 grid gap-4"].join(" ")}
          sendStartGame={sendStartGame}
        />
      </div>
    </div>
  );
};

export default Lobby;
