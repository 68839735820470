import React from "react";
import { COLORS } from "./../../colors";
import Button from "./../Button/Button";
import { ReactComponent as PlayerCountImage } from "./assets/playerCountImage.svg";

const GameCard = (props) => {
  return (
    <div
      className="flex p-4 rounded-lg m-4"
      style={{ backgroundColor: COLORS.gameCardBackgroud }}
    >
      <div
        className="mr-4 rounded-lg"
        style={{ backgroundColor: "blue", height: "15rem", minWidth: "25%" }}
      ></div>
      <div
        className="flex flex-col text-left"
        style={{
          color: COLORS.commonTextLight,
          justifyContent: "space-between",
        }}
      >
        <p className="font-bold text-xl">Tik Tac Toe</p>
        <p className="my-4 max-w-full">
          Quis pretium libero magna at dictum curabitur condimentum in. Felis
          feugiat vulputate mauris nunc
        </p>
        <div className="flex">
          <PlayerCountImage />
          <span className="mr-auto text-sm/[30px]">2-3</span>
          <Button
            className="px-4 py-2 mx-1 rounded-lg font-['Poppins']"
            style={{
              backgroundColor: COLORS.commonButtonPrimary,
              color: COLORS.commonTextLight,
            }}
            onClick={props.sendStartGame}
          >
            Play Now
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GameCard;
