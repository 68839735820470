export const config = {
    AVATARS: [
        '/avatars/avatar_0.svg',
        '/avatars/avatar_1.svg',
        '/avatars/avatar_2.svg',
        '/avatars/avatar_3.svg',
        '/avatars/avatar_4.svg',
        '/avatars/avatar_5.svg',
        '/avatars/avatar_6.svg',
        '/avatars/avatar_7.svg',
        '/avatars/avatar_8.svg',
        '/avatars/avatar_9.svg',
        '/avatars/avatar_10.svg',
        '/avatars/avatar_11.svg'
    ]
}