import React from "react";
import Search from "./Search/Search";
import { COLORS } from "./../../../colors";

const GameSearchAndFilter = () => {
  return (
    <div className="flex justify-between mt-6">
      <Search
        className="flex mx-1 rounded-lg px-4 py-2 border-2 border-solid mr-auto"
        style={{ borderColor: COLORS.commonLightBorder, minWidth: "25vw" }}
      />
      {/* TODO: Change Below to Filter DropDowns */}
      <Search
        className="flex mx-1 rounded-lg px-4 py-2 border-2 border-solid"
        style={{ borderColor: COLORS.commonLightBorder }}
      />
      <Search
        className="flex mx-1 rounded-lg px-4 py-2 border-2 border-solid"
        style={{ borderColor: COLORS.commonLightBorder }}
      />
    </div>
  );
};

export default GameSearchAndFilter;
