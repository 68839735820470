import React from "react";
import { COLORS } from "../../../../../colors";

const SearchIcon = React.memo(() => (
  <svg
    width="24"
    height="24"
    fill="none"
    aria-hidden="true"
    className="flex-none"
  >
    <path
      d="m19 19-3.5-3.5"
      stroke={COLORS.commonLightBorder}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
    <circle
      cx="11"
      cy="11"
      r="6"
      stroke={COLORS.commonLightBorder}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></circle>
  </svg>
));

SearchIcon.displayName = "SearchIcon";

export default SearchIcon;
