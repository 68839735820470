import React, { useState } from 'react';
import './SwitchButton.css';
import { COLORS } from "../../../../colors";

const SwitchButton = (props) => {
    const [isOn, setIsOn] = useState(false);
  
    const handleToggle = () => {
      setIsOn((prevIsOn) => !prevIsOn);
    };
  
    return (
        <div className="flex items-center">
            <label className="switch">
                <input type="checkbox" checked={isOn} onChange={handleToggle} />
                <span className="slider"></span>
            </label>
            <h1 className="text-l pl-4 font-bold" style={{color: COLORS.commonTextLight}}>{props.label}</h1>
        </div>
    );
};
  
export default SwitchButton; 
