import "./App.css";
import React, { useState } from 'react'
import { featureFlags } from "./feature_flags";
import Lobby from "./Pages/Lobby/Lobby";
import Avatar from "./Pages/Avatar/Avatar";
import TicTacToe from "./Pages/TicTacToe/TicTacToe";
import FloatingVideo from './Components/FloatingVideo/FloatingVideo'
import {SocketContext, socket, wrapper} from './context/socket';

window.featureFlags = featureFlags;

if (featureFlags.shouldSetupWebRTC) {
  wrapper.init();
}

function pageRouter(params) {
    const [currentPage, setcurrentPage] = useState("avatar");
    const [userDetails, setuserDetails] = useState({});
    window.MySocket = socket; // for testing messages

    switch(currentPage) {
      // should we move user details to context?
      case 'avatar':
        return <Avatar setPage={setcurrentPage} setuserDetails={setuserDetails} />;

      case 'lobby':
        return <Lobby setPage={setcurrentPage} setuserDetails={setuserDetails} userDetails={userDetails} setVideoStreams={params.setVideoStreams} />;

      case 'tictactoe':
        return <TicTacToe userDetails={userDetails}/>;
    }
}

function App() {
    const [videoStreams, setVideoStreams] = useState([]);
    const params = {
      setVideoStreams
    }
    return (
      <SocketContext.Provider value={{socket, "GSWebRTCWrapper":wrapper}}>
        <FloatingVideo videoStreams={videoStreams}/>
        {pageRouter(params)}
      </SocketContext.Provider>
    );

}

export default App;
