import React, { useState } from 'react'

const AvatarTile = (props) => {

  const handleClick = () => {
    if (props.setActiveImage) {
      props.setActiveImage(props.id)
    }
  }; 

  return (
    <div
      className={`${props.externalClasses ? props.externalClasses : '' } ${( props.id && (props.activeId === props.id)) ? "border border-white border-4 scale-115 transform transition-all duration-200": ''}`}  
      onClick={handleClick}  
      style={{backgroundColor: props.color , height: "5rem", width: "5rem"}}>
        <img src ={props.avatarPath} />
    </div>
  )

}

export default AvatarTile;