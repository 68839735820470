import React, { useState, useRef, useEffect } from 'react';
import { COLORS } from "./../../../../colors";

const GameCode = (props) => {
    const [inputCodes, setInputCodes] = useState(['', '', '', '', '']);
    const inputRefs = useRef([]);

    useEffect(() => {
        // FIX THIS? Should we move inputCodes to the parent?
        if(props.setLobbyCodeOnJoin){
            console.log("INPUT CODES HAS CHANGED", inputCodes);
            props.setLobbyCodeOnJoin(inputCodes.join(''));
        }
    }, [inputCodes]);
  
    const handleChange = (e, index) => {
        const value = e.target.value;

        if (/[^a-zA-Z0-9]/.test(value)) {
            return;
        }
  
        // Update the current box
        inputCodes[index] = value;
        console.log(index,value)
  
        // If it's not the last box, move to the next one
        if (index < inputCodes.length - 1 && value !== '') {
            inputRefs.current[index + 1].focus();
        }
  
        setInputCodes([...inputCodes]);
    };
  
    const handleKeyDown = (e, index) => {
        // TODO: Handle Arrow Left and Right
        if (e.key === "Backspace" && inputCodes[index] === '' && index > 0) {
            console.log("Triggered key down logic", inputCodes[index], index)
            inputRefs.current[index - 1].focus();
        }
    };

    const handlePaste = (e) => {
        const pastedData = e.clipboardData.getData('text');
        if (pastedData < 5) {
            console.log("less than 5 chars");
        } else {
            setInputCodes(pastedData.substring(0, 5).split(''));
            inputRefs.current[4].focus();
        }
    }

    useEffect(() => {
        inputRefs.current[0].focus();
    }, []);

    useEffect(() => {
      if(props.lobbyCode) {
        setInputCodes(props.lobbyCode.split(''));
      }
    }, []);
    
    return (
    <div className="flex items-center justify-center">
        {inputCodes.map((code, index) => (
        <input
            key={index}
            ref={(el) => (inputRefs.current[index] = el)}
            type="text"
            readOnly={ props.hasJoinedRoom ? true : false }
            maxLength="1"
            value={code}
            onChange={(e) => handleChange(e, index)}
            onPaste={handlePaste}
            onKeyDown={(event) => handleKeyDown(event, index)}
            className={`text-3xl font-bold rounded-xl  placeholder-center text-center pb-2 border-2 focus:outline-none focus:border-yellow-500  ${( (code != "")) ? "border border-2 border-yellow-500": ''}`}
            placeholder="-"
            style={{
                color: COLORS.commonButtonSecondary,
                height: '4rem',
                width: '3rem',
                margin: '0 4px',
                backgroundColor: COLORS.commonBackGroundPrimary
            }}
        />
        ))}
    </div>
    );
  }

export default GameCode;
