import React from "react";
import { COLORS } from "./../../../../colors";
import SearchIcon from "./assets/SearchIcon";

const Search = (props) => {
  return (
    <div className={props.className} style={props.style}>
      <span className="mr-auto" style={{ color: COLORS.commonLightBorder }}>
        Search
      </span>
      <SearchIcon />
    </div>
  );
};

export default Search;
