import React , {useState} from 'react'

const Input = (props) => {
    return (
      <input
        type="text"
        value = {props.value}
        onChange={props.onChange}
        className={props.className}
        style={props.style}
        placeholder={props.children}
        disabled = {props.disabled}
      />
    )
}

export default Input