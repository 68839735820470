import React, { useState } from 'react'
import InputBox from '../../Components/InputBox/InputBox'
import AvatarTile from '../../Components/AvatarTile/AvatarTile'
import Button from '../../Components/Button/Button'
import { COLORS } from '../../colors'
import { config } from './AvatarConstants';
// import SVGImage from './../../Components/SVGImage/SBGImage'

const Avatar = (props) => {  

  // To handle input logic
  const [status, setStatus] = useState('empty');
  const [activeId, setActiveId] = useState(config.AVATARS[0]);
  const [userName, setUserName] = useState("");

  const handleInputChange = (event) => {
    const value = event.target.value;
    setUserName(value);
    setStatus("typing");
  };

  // To handle avatar selection
  let gameCardGridColumn = "grid-cols-6";
  

  function handleSubmit() {
    setStatus('submitting');
    try {
      props.setuserDetails({
        userName: userName,
        userAvatarPath: activeId
      })
      props.setPage("lobby");
      setStatus('success');
    } catch (err) {
      setStatus('typing');
      // Add better handling
    }
  }

  return (
    <div className="flex min-h-screen justify-center items-center" style={{backgroundColor: COLORS.commonBackGroundPrimary}}>
        <div className="flex flex-col">
            <InputBox  
              className="border border-gray-400 p-2 m-4 rounded-md shadow-sm  text-lg focus:outline-none focus:border-blue-500 w-full placeholder-center text-center"
              style={{backgroundColor: COLORS.commonBackGroundPrimary, color: COLORS.commonTextLight}}
              value = {userName}
              onChange={handleInputChange}
              disabled = {status === "submitting"}
            >
              Enter your name 
            </InputBox>

            <h1 className="text-xl pt-10 pb-4" style={{color: COLORS.commonTextLight}} >Pick an avatar</h1>   

            <div className={[gameCardGridColumn, "mt-2 grid"].join(" ")}>
              {config.AVATARS.map((item) => (
                <AvatarTile key={item}id={item} externalClasses="flex rounded-xl m-2 hover:scale-100 transform transition-all duration-200" activeId={activeId} setActiveImage={setActiveId} avatarPath={item} />
              ))}
            </div>

            <div className='flex justify-center items-center'>
              <Button onClick={handleSubmit} className="py-2 px-4 mt-8 rounded-lg text-white" style={{backgroundColor: COLORS.commonButtonPrimary}}>Let's Play &#x2794;</Button>
            </div>
        </div>
    </div>
  )
}


export default Avatar
