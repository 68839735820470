import React, {useEffect,useRef} from 'react';
import Draggable from 'react-draggable';

const FloatingVideo = (props) => {
  const videoRefs = useRef([]);

  useEffect(() => {
    props.videoStreams.forEach((streamObj, index) => {
      if (videoRefs.current[index]) {
        videoRefs.current[index].srcObject = streamObj;
      }
    });
  }, [props.videoStreams]);

  return (
    props.videoStreams.length !== 0 ?
      <div className="absolute z-10">
      <Draggable handle=".draggable-header">
          <div>
              <div className="h-5 w-56 bg-indigo-700 draggable-header"></div>
              <ul>
              {props.videoStreams.map((streamObj, index) => (
                <video className="h-32 w-56" key={index} ref={(ref) => (videoRefs.current[index] = ref)} muted={streamObj.customMuted} customname={streamObj.customName} autoPlay />
              ))}
              </ul>
          </div>
      </Draggable>
      </div> : null
  )
}

export default FloatingVideo;
