import React, { useState } from 'react';

import { COLORS } from "./../../../colors";
import AvatarTile from '../../../Components/AvatarTile/AvatarTile'
import SwitchButton from './SwitchButton/SwitchButton';
import GameCode from './GameCode/GameCode';
import Button from './../../../Components/Button/Button';
import CopyCodeComponent from './CopyCodeComponent/CopyCodeComponent';

const PlayerLobby = (props) => {
  const [lobbyCodeOnJoin, setLobbyCodeOnJoin] = useState('');
  // const [lobbyCodeOnCreate, setLobbyCodeOnCreate] = useState(props.lobbyCode);

  let gameCardGridColumn = "grid-cols-6";

    return (
      <div className="flex m-6 flex-col justify-center" style={{backgroundColor: COLORS.gameCardBackgroud}} >
        <h1 className="text-l font-bold p-4" style={{color: COLORS.commonTextLight}} >SECRET CODE</h1>

        { props.hasJoinedRoom ? 
          <>
            <div className="flex justify-around">
              <div className="flex justify-between items-center">
                <GameCode hasJoinedRoom={props.hasJoinedRoom} lobbyCode={props.lobbyCode} /> 
                {/* If Player Creates the Lobby, then props.lobbyCode will have a value. Else When Player clicks Join Lobby lobbyCodeOnJoin will be set */}
                <CopyCodeComponent lobbyCode={props.lobbyCode ? props.lobbyCode : lobbyCodeOnJoin} /> 
              </div>
              <SwitchButton label="Voice Channel"/>
            </div>

            <div className="flex w-1/2 flex-col mt-4">
              <div className={["grid-cols-2  p-2 grid"].join(" ")}>
                <h1 className="text-l  font-bold" style={{color: COLORS.commonTextLight}} >Players</h1>
                <h1 className="text-l  text-right" style={{color: COLORS.commonTextLight}} >{props.playerList.length} of 6 players</h1>
              </div>
              
              <div className={[gameCardGridColumn, "grid p-2"].join(" ")}>
                {props.playerList.map((item) => (
                  <AvatarTile key={Date.now()+item.userAvatarPath+item.userName} externalClasses="flex rounded-xl m-2 hover:scale-100 transform transition-all duration-200" avatarPath={item.userAvatarPath} />
                ))}
                        {/* <AvatarTile  id={0} activeId = {0} onShow = {0}  avatarPath = {require("../../../assets/avatars/avatar_0.svg").default} />
                        <AvatarTile  id={1} activeId = {0} onShow = {0}  avatarPath = {require("../../../assets/avatars/avatar_1.svg").default} />
                        <AvatarTile  id={2} activeId = {0} onShow = {0}  avatarPath = {require("../../../assets/avatars/avatar_2.svg").default} />
                        <AvatarTile  id={3} activeId = {0} onShow = {0}  avatarPath = {require("../../../assets/avatars/avatar_3.svg").default} />
                        <AvatarTile  id={4} activeId = {0} onShow = {0}  avatarPath = {require("../../../assets/avatars/avatar_4.svg").default} />
                        <AvatarTile  id={5} activeId = {0} onShow = {0}  avatarPath = {require("../../../assets/avatars/avatar_5.svg").default} /> */}
              </div>
            </div>
          </> :
          <>
            <GameCode hasJoinedRoom={props.hasJoinedRoom} lobbyCode={props.lobbyCode} setLobbyCodeOnJoin={setLobbyCodeOnJoin} /> 
            <Button
              className="px-4 py-2 mx-1 rounded-lg font-['Poppins']"
              style={{
                backgroundColor: COLORS.commonButtonPrimary,
                color: COLORS.commonTextLight,
              }}
              onClick={() => props.handleJoinLobby(lobbyCodeOnJoin)}
            >
              Join Lobby
            </Button>
          </>
        }
        
            
      </div>
    );
  };
  
  export default PlayerLobby;