import React,{ useContext, useState, useEffect } from 'react'
import TicTacToeGameBoard from './TicTacToeGameBoard/TicTacToeGameBoard'
import { SocketContext } from './../../context/socket';
import Button from './../../Components/Button/Button';

const TicTacToe = (props) => {
    const socket = useContext(SocketContext).socket;
    const [squares, setSquares] = useState(Array.from({ length: 3 }, (_, rowId) => Array.from({ length: 3 }, (_, colId) => ({ rowId, colId }))).flat());
    const [prompt, setPrompt] = useState('Hi Welcome!');
    const [isMyTurn, setIsMyTurn] = useState(false);
    const [isGameCompleted, setIsGameCompleted] = useState(false);

    const onSquareClicked = (i, j) => {
        console.log(i,j);
        // console.log()
        // squares[i*3 + j].squareOccupied = true;
        const updatedSquares = [...squares];
        updatedSquares[i*3 + j].squareOccupied = true;
        updatedSquares[i*3 + j].avatarPath = props.userDetails.userAvatarPath;
        setSquares(updatedSquares);
        socket.emit('ticTacToe', {event: "GameEvent", action: "selected-square", rowId: i, colId: j, avatarPath:props.userDetails.userAvatarPath});
        setPrompt("it's your opponents Turn!");
        setIsMyTurn(false);
    };

    socket.on("opponent-selected-square", (data) => {
        console.log("mysocketid", socket.id);
        const i = data.rowId;
        const j = data.colId;
        const updatedSquares = [...squares];
        updatedSquares[i*3 + j].squareOccupied = true;
        updatedSquares[i*3 + j].avatarPath = data.avatarPath;
        setSquares(updatedSquares);
        setPrompt("it is your Turn!");
        setIsMyTurn(true);
    })

    socket.on("set-starting-player", (data) => {
        if(data.startingPlayer == socket.id) {
            setPrompt("it is your Turn!");
            setIsMyTurn(true);
        } else {
            setPrompt("it's your opponents Turn!")
            setIsMyTurn(false);
        }
    })

    socket.on("declare-winner", (data) => {
        const i = data.rowId;
        const j = data.colId;
        const updatedSquares = [...squares];
        updatedSquares[i*3 + j].squareOccupied = true;
        updatedSquares[i*3 + j].avatarPath = data.avatarPath;
        setSquares(updatedSquares);
        if(data.winner) {
            setPrompt("You Won!! Congrats!");
        } else {
            setPrompt("Your Opponent Won! Better luck next time!");
        }
        setIsMyTurn(false);
        setIsGameCompleted(true);
    });

    socket.on("declare-draw", () => {
        const i = data.rowId;
        const j = data.colId;
        const updatedSquares = [...squares];
        updatedSquares[i*3 + j].squareOccupied = true;
        updatedSquares[i*3 + j].avatarPath = data.avatarPath;
        setSquares(updatedSquares);
        setPrompt("it's a Draw!");
        setIsGameCompleted(true);
    });

    socket.on("restart-game", () => {
        setIsGameCompleted(false);
        setPrompt("Hi Welcome!");
        setIsMyTurn(false);
        const updatedSquares = Array.from({ length: 3 }, (_, rowId) => Array.from({ length: 3 }, (_, colId) => ({ rowId, colId }))).flat();
        setSquares(updatedSquares);
    });

    const restartGameHandler = () => {
        setIsGameCompleted(false);
        setPrompt("Hi Welcome!");
        setIsMyTurn(false);
        const updatedSquares = Array.from({ length: 3 }, (_, rowId) => Array.from({ length: 3 }, (_, colId) => ({ rowId, colId }))).flat();
        setSquares(updatedSquares);
        socket.emit('ticTacToe', {event: "RestartGame"});
    }

    // useEffect(() => {
    //     // This code will be executed once when the component is loaded
    //     console.log('tictactoe is loaded');
    //     if (props.userDetails.isAdmin) {
    //         console.log('tictactoe sending start game');
    //         socket.emit('ticTacToe', {event: "StartGame"})
    //     }
    //   }, []);

    return (
        <>
        <div className={["flex justify-center items-center flex-col", isMyTurn ?  "" : "pointer-events-none"].join(' ')}>
        <TicTacToeGameBoard squares={squares} onSquareClicked={onSquareClicked}/>
        <h2>{prompt}</h2>
        </div>
        {isGameCompleted && props.userDetails.isAdmin ? <Button className="rounded-full bg-cyan-500" onClick={restartGameHandler}>Restart Game</Button> : null}
        </>
        
    )
}

export default TicTacToe;
