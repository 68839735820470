import React from "react";
import { COLORS } from "./../../../colors";
import Button from "./../../../Components/Button/Button";
import AvatarTile from "./../../../Components/AvatarTile/AvatarTile";

const LobbyHeader = (props) => {
  return (
    <div className="flex justify-between">
      <Button
        className="px-4 py-2 mx-1 rounded-lg font-['Poppins'] mr-auto"
        style={{ backgroundColor: "grey", color: "black" }}
      >
        LOGO
      </Button>
      
      {/* TODO: Should we move this to a new component? */}
      {
        props.hasJoinedRoom ? (
          <Button
            className="px-4 py-2 mx-1 rounded-lg font-['Poppins']"
            style={{
              backgroundColor: COLORS.commonButtonSecondary,
              color: COLORS.commonTextDark,
            }}
            onClick={props.triggerJoinLobbyPopUp}
          >
            Exit Lobby
          </Button>
        ) : (
          <>
            <Button
              className="px-4 py-2 mx-1 rounded-lg font-['Poppins']"
              style={{
                backgroundColor: COLORS.commonButtonPrimary,
                color: COLORS.commonTextLight,
              }}
              onClick={props.handleCreateGame}
            >
              Create Room
            </Button>
            <Button
              className="px-4 py-2 mx-1 rounded-lg font-['Poppins']"
              style={{
                backgroundColor: COLORS.commonButtonSecondary,
                color: COLORS.commonTextDark,
              }}
              onClick={props.triggerJoinLobbyPopUp}
            >
              Join Room
            </Button>
          </>
        )
      }
      <AvatarTile avatarPath={props.userDetails.userAvatarPath} />
    </div>
  );
};

export default LobbyHeader;
