import React from "react";
import GameCard from "./../../../Components/GameCard/GameCard";

const GamesGrid = (props) => {
  return (
    <div className={props.className}>
      <GameCard sendStartGame={() => {props.sendStartGame()}}>GameCard</GameCard>
      <GameCard>GameCard</GameCard>
      <GameCard>GameCard</GameCard>
      <GameCard>GameCard</GameCard>
    </div>
  );
};

export default GamesGrid;
