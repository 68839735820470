import React from 'react';
import AvatarTile from '../../../Components/AvatarTile/AvatarTile';

const TicTacToeGameBoard = (props) => {
  return (
    <div className="grid gap-4 grid-cols-3 grid-rows-3 bg-black">
        {props.squares.map((value, index) => (
            <div key={index} className="h-20 w-20 bg-white hover:bg-cyan-200" onClick={() => props.onSquareClicked(value.rowId, value.colId)}>
                {value.squareOccupied ? <AvatarTile avatarPath={value.avatarPath} />: null}
            </div>
        ))}
    </div>
  )
}

export default TicTacToeGameBoard;
