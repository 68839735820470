export const GSWebRTCWrapper = (socket) => {
    let setVideoStreams, lobbyCode, peerConnection;
    const configuration = {
        iceServers: [
            {
            urls: [
                'stun:stun1.l.google.com:19302',
                'stun:stun2.l.google.com:19302',
            ],
            },
        ],
        iceCandidatePoolSize: 10,
    };

    const init = () => {
        peerConnection = new RTCPeerConnection(configuration);
        peerConnection.createDataChannel("myDatachannel"); // check if we need to open required channels beforehand
        console.log(peerConnection);
        registerPeerConnectionListeners();
        socket.on('webrtc_setup', handleWebRTCSetupMessages);
    }

    function registerPeerConnectionListeners() {
        peerConnection.addEventListener('icecandidate', event => {
            if (event.candidate) {
              console.log("got candidates", event.candidate);
              socket.emit("webrtc_setup", {'message':'new_ice_candidate', 'new_ice_candidate': event.candidate, lobbyCode});
            }
        });

        peerConnection.addEventListener('icegatheringstatechange', () => {
          console.log(
              `ICE gathering state changed: ${peerConnection.iceGatheringState}`);
        });
      
        peerConnection.addEventListener('connectionstatechange', () => {
          console.log(`Connection state change: ${peerConnection.connectionState}`);
        });
      
        peerConnection.addEventListener('signalingstatechange', () => {
          console.log(`Signaling state change: ${peerConnection.signalingState}`);
        });
      
        peerConnection.addEventListener('iceconnectionstatechange ', () => {
          console.log(
              `ICE connection state change: ${peerConnection.iceConnectionState}`);
        });

        peerConnection.addEventListener('track', (event) => {
            console.log("Got Track:", event)
            if(event.track.kind === "video") {
                
                const [remoteStream] = event.streams;
                remoteStream.customMuted = false;
                remoteStream.customName = "remote";
                setVideoStreams((prevStreams) => [...prevStreams, remoteStream]);
            } else {
                console.log("got non- video track", event)
            }
            
        });
    }

    const handleWebRTCSetupMessages = async (message) => {
        if (message.message === 'offer') {
            console.log("gotOffer", message.offer)
            peerConnection.setRemoteDescription(new RTCSessionDescription(message.offer));
            const answer = await peerConnection.createAnswer();
            await peerConnection.setLocalDescription(answer);
            socket.emit("webrtc_setup", {'message':'answer', answer, lobbyCode})
        } else if (message.message === 'answer') {
            console.log("gotAnswer", message.answer)
            const answer = new RTCSessionDescription(message.answer)
            await peerConnection.setRemoteDescription(answer);
        } else if (message.message === 'new_ice_candidate'){
            console.log("new_ice_candidate", message.new_ice_candidate);
            if (message.new_ice_candidate) {
                try {
                    await peerConnection.addIceCandidate(message.new_ice_candidate);
                } catch (e) {
                    console.error('Error adding received ice candidate', e);
                }
            }
        } else {
            console.log("unknown webrtc message", message)
        }
    }

    const createOffer = async () => {
        const offer = await peerConnection.createOffer();
        await peerConnection.setLocalDescription(offer);
        socket.emit("webrtc_setup", {'message':'offer', offer, lobbyCode});
    }

    const setLocalStreamTracks = (localStream) => {
        localStream.getTracks().forEach(track => {
            console.log("Adding to local tracks", track, localStream)
            peerConnection.addTrack(track, localStream);
        });
    }

    const setFunc = (a) => { // update this use better name + logic
        setVideoStreams = a;
    }

    const setLobbyCode = (xlobbycode) => {
        lobbyCode = xlobbycode;
    }

    return {
        init,
        createOffer,
        setLocalStreamTracks,
        setFunc,
        setLobbyCode
    }
}